import React, { useContext } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import isEmpty from '../../utils/isEmpty';
import Icon from '../Icon';
import styles from './InfoBox.module.scss';
import { SiteSettingContext } from '../../containers/BasePage/BasePage';

const styledAddress = (address) =>
    address.split(',').map((item, i) => {
        return <p key={i}>{item}</p>;
    });

const googleMapsLink = (address) =>
    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        address
    )}`;

const cleanTelephone = (telephone) => telephone.replace(/[^\d]/g, '');

const InfoBox = ({
    title,
    text,
    address,
    telephone,
    href,
    label,
    bookingHref,
    bookingLabel,
    closestStation,
    modifiers = [],
    venueName,
    city,
    zipCode
}) => {
    
    const siteSetting = useContext(SiteSettingContext);

    if (
        isEmpty(title) &&
        isEmpty(text) &&
        isEmpty(address) &&
        isEmpty(telephone) &&
        isEmpty(href) &&
        isEmpty(label) &&
        isEmpty(address) &&
        isEmpty(bookingHref)
    ) {
        return null;
    }

    if (text) {
        text = text.split('</a>').join('<span></span></a>');
    }

    const classes = classNames(
        styles['InfoBox'],
        modifiers.map((mod) => styles['InfoBox--' + mod])
    );

    console.log(zipCode, city);
    

    return (
        <section className={classes}>
            {title && <h2 className={styles['InfoBox__Title']}>{title}</h2>}
            {address && address.length > 2 && (
                <div className={styles['InfoBox__AddressWrapper']}>
                    <div className={styles['InfoBox__IconMargin']}>
                        <div className="InfoBox__AddressIconWrapper">
                            <Icon type="place" size="large" color="black800" />
                        </div>
                    </div>
                    <div clasName={styles['InfoBox__AddressBox']}>

                        {venueName && (
                            <div className={styles['InfoBox__Venue']}>
                                {venueName}
                            </div>
                        )}

                        <div className={styles['InfoBox__Address']}>
                            {styledAddress(address)}
                            {zipCode && (
                                <p>{zipCode}</p>
                            )}
                            {city && (
                                <p>{city}</p>
                            )}
                        </div>

                        <a
                            className={styles['InfoBox__LinkExternal']}
                            href={googleMapsLink(address)}>
                            {i18n.t('EventPage.mapsLink')}
                            <span className={styles['InfoBox__IconExternal']}>
                                <Icon type="external" size="small" color="pink700" />
                            </span>
                        </a>

                    </div>
                </div>
            )}
            {telephone && (
                <div className={styles['InfoBox__TelephoneWrapper']}>
                    <div className={styles['InfoBox__IconMargin']}>
                        <Icon type="phone" size="large" color="black800" />
                    </div>
                    <a
                        className={styles['InfoBox__Link']}
                        href={'tel:' + cleanTelephone(telephone)}>
                        {telephone}
                    </a>
                </div>
            )}

            {!!closestStation && closestStation.name && (
                <div className={styles['InfoBox__AddressWrapper']}>
                    <div className={styles['InfoBox__IconMargin']}>
                        <div className="InfoBox__AddressIconWrapper">
                            <Icon type="bus" size="large" color="black800" />
                        </div>
                    </div>
                    <div className={styles['InfoBox__ClosestStation']}>
                        <p>
                            {i18n.t('InfoBox.closestStation')}{' '}
                            {closestStation.name}
                        </p>
                        {closestStation.lang === 'sv' ? (
                            siteSetting?.sl?.websiteLink && (
                                <a
                                    className={styles['InfoBox__Link']}
                                    href={siteSetting.sl.websiteLink}>
                                    {i18n.t('InfoBox.swe')}
                                </a>
                            )
                        ) : (
                            <>
                                {siteSetting?.sl?.iosDownloadLink && (
                                    <p>
                                        <a
                                            className={styles['InfoBox__LinkExternal']}
                                            href={
                                                siteSetting.sl.iosDownloadLink
                                            }>
                                            {i18n.t('InfoBox.ios')}
                                            <span className={styles['InfoBox__IconExternal']}>
                                                <Icon type="external" size="small" color="pink700" />
                                            </span>
                                        </a>
                                    </p>
                                )}
                                {siteSetting?.sl?.androidDownloadLink && (
                                    <p>
                                        <a
                                            className={styles['InfoBox__LinkExternal']}
                                            href={
                                                siteSetting.sl
                                                    .androidDownloadLink
                                            }>
                                            {i18n.t('InfoBox.android')}
                                            <span className={styles['InfoBox__IconExternal']}>
                                                <Icon type="external" size="small" color="pink700" />
                                            </span>
                                        </a>
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
            {!!bookingHref && (
                <div className={styles['InfoBox__LinkWrapper']}>
                    <div className={styles['InfoBox__IconMargin']}>
                        <Icon type="external" size="large" color="black800" />
                    </div>
                    <a className={styles['InfoBox__Link']} href={bookingHref}>
                        {bookingLabel || bookingHref}
                    </a>
                </div>
            )}
        </section>
    );
};

InfoBox.propTypes = {
    address: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
    href: PropTypes.string,
    label: PropTypes.string,
    telephone: PropTypes.string,
};

InfoBox.defaultProps = {
    address: '',
    text: '',
    link: '',
    title: '',
    href: '',
    label: '',
    telephone: '',
};

export default InfoBox;
